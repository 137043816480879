<template>
  <v-row>
    <v-col cols="12" md="4">
      <h3>Additional scripts</h3>
      <p>Adding scripts to your storefront might be beneficial to your business.</p>
    </v-col>
    <v-col cols="12" md="8">
      <v-tabs v-model="tab" align-with-title>
        <v-tab v-for="item in items" :key="item" :href="'#tab-' + item">
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-5" v-model="tab">
        <v-tab-item value="tab-Head">
          <v-card flat>
            <span>{{ head }}</span>
            <codemirror class="mt-3" :options="cmOptions" v-model="$parent.attributes.addionalScriptHead" />
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-Body">
          <v-card flat>
            <span>{{ body }}</span>
            <codemirror class="mt-3" :options="cmOptions" v-model="$parent.attributes.addionalScriptBody" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>
<script>
import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/3024-night.css';

export default {
  components: {
    codemirror,
  },
  data() {
    return {
      tab: 'Head',
      items: ['Head', 'Body'],
      head: `Custom HTML tags will be added right before </head> tag. Only accept <style>, <meta>, <link>, <script>, <noscript> tags`,
      body: `Custom HTML tags will be added right before </body> tag. Only accept <script>, <noscript> tags`,
      headScript: '',
      bodyScript: '',
      cmOptions: {
        tabSize: 4,
        mode: 'text/javascript',
        theme: '3024-night',
        lineNumbers: true,
        line: true,
      },
    };
  },
  methods: {
    highlighter(code) {
      console.log(code);
      return code; //returns html
    },
  },
};
</script>
<style lang="scss">
// required class
.my-editor {
  background: #2d2d2d;
  color: #ccc;
  margin-top: 10px;
  min-height: 100px;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

// optional
.prism-editor__textarea:focus {
  outline: none;
}

// not required:
.height-300 {
  height: 300px;
}
</style>
