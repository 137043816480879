<template>
  <v-row>
    <v-col cols="12" md="4">
      <h3>Favicon</h3>
      <p>Will be scaled down to 32px x 32px</p>
    </v-col>
    <v-col cols="12" md="8">
      <UploadOneImage :image="$parent.image" @callback="getImageUpLoad" />
    </v-col>
  </v-row>
</template>
<script>
export default {
  methods: {
    getImageUpLoad(image) {
      this.$parent.image = image;
      this.$parent.attributes.favIcon = image.src;
      this.$forceUpdate();
    },
  },
};
</script>
